import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Rules for Neches River Educational Cruises</h1>
              <ol>
                <li>No more than 36 people (includes students and chaperones) can take part in any school educational cruise on “The Ivory Bill”.</li>
                <li>A minimum of 20 students are required to run the free environmental education classes (grant requirement).</li>
                <li>Teachers must select their chaperones, making certain that they don’t have more chaperones than allowed. No more than 1 chaperone for every 6 students and not less than one chaperone for every 8 students.  (Example: 24 students trip, 1 teacher and no more than 4 chaperones making a total of 29)</li>
                <li>Teachers must supply a separate list of students, teachers and chaperones for each trip prior to boarding the boat.</li>
                <li>Ensure that chaperones arrive with students. No more than 8 or less than 6 students should be pre-assigned to an adult chaperone. Chaperones will be expected to remain with their assigned students for the duration of the field trip, both on the boat and on land.</li>
                <li>Please make sure parent chaperones understand they are to supervise students. They should not bring younger siblings/children who may interfere with their role as chaperone.</li>
                <li>Please ensure parent chaperones understand that their job is to help keep students safe and on-task. It is counter-productive for parent chaperones to disrupt the class by talking to each other or making phone calls during program time. Chaperones are expected to actively participate in all activities.</li>
                <li>"The Ivory Bill" is a covered boat and this allows trips during marginal weather. It is the responsibility of the Big Thicket Association to cancel trips due to weather. We discourage teachers cancelling their program. Many classrooms are turned down every year because our programs fill up. When teachers cancel at the last minute, we cannot fill the slot. However, we understand that unexpected situations arise. If you need to cancel, please let us know as soon as possible.</li>
                <li>If teachers plan on their students eating lunch before or after the trip, there are picnic tables at the departure location. Pavilions at Collier’s Ferry Park make a nice place to picnic.</li>
                <li>Unless otherwise arranged, all school trip departures will leave from Collier’s Ferry Park. (Arrangements from other locations should be arranged at least one week before the trip.)</li>
                <li>All requests for programs must occur at least two weeks prior to departure.</li>
              </ol>
              <p>If you have any additional questions, please email NRIBEDU@GMAIL.COM or BTAAssistant@gmail.com, or call (409)-651-5326.</p>
              <a
                className="navbar-item"
                href="/img/nrib-educational-rules-2019.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Printable Rules
              </a>
              <h1>Contact</h1>
              <form
                name="teacher-rules"
                method="post"
                action="/education/teacher-registration/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="teacher-rules" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Your name (to acknowledge that you read the rules)
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Accept, Continue to Registration
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
